import * as chains from "./chains";

// If you add coins for a new network, make sure Weth address (for the router you are using) is the first entry

const ALTCOINCHAINTokens = [
  {
    name: "Altcoin",
    abbr: "ALT",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "SwapinDao",
    abbr: "SWAPD",
    address: "0x67e7ebdA5CBA73f5830538B03E678A1b45517dd7",
  },
  {
    name: "WATT",
    abbr: "WATT",
    address: "0x6645143e49B3a15d8F205658903a55E520444698",
  },
  {
    name: "Altminers",
    abbr: "MALT",
    address: "0xaF5D066Eb3e4147325d3ed23F94bc925fbf3B9EF",
  },
  {
    name: "AltPepe",
    abbr: "PEPE",
    address: "0xD350EcD60912913cc15d312ef38adECa909eCDD5",
  },
  {
    name: "Pepita",
    abbr: "PEPI",
    address: "0xbB1f8B3a73a0B5084aF9a95e748f9d84DdBA6E88",
  },
  {
    name: "Old LBC, do not buy",
    abbr: "LBC",
    address: "0x59dbfdc05a1E460DD374167a4041c31275EbcE04",
  },
  {
    name: "0xSCAM token",
    abbr: "SCAM",
    address: "0x75b37574C2317CCBA905E2c628d949710627C20A",
  },
  {
    name: "Supernova",
    abbr: "NOVA",
    address: "0x1E1e5bd07dc6583e1639C0C760EEB8eC35dd3b2b",
  },
];
const ETHOTokens = [
  {
    name: "ETHO",
    abbr: "ETHO",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "SwapinDao",
    abbr: "SWAPD",
    address: "0x67e7ebdA5CBA73f5830538B03E678A1b45517dd7",
  },
  {
    name: "EthoPepe",
    abbr: "PEPE",
    address: "0xD350EcD60912913cc15d312ef38adECa909eCDD5",
  },
  {
    name: "Pepita",
    abbr: "PEPI",
    address: "0xa33c57c0D66d8d243796f74958A2904B90b97245",
  },
];
const PLANQTokens = [
  {
    name: "PLANQ",
    abbr: "PLQ",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "USDT",
    abbr: "USDT",
    address: "0xfD6fF17b542260f95660BBD71470Fe6eEC72801D",
  },
  {
    name: "USDC",
    abbr: "USDC",
    address: "0x75E20C5d4aade76143b8b74d1C5E2865347f9d3B",
  },
  {
    name: "Wrapped BNB",
    abbr: "wBNB",
    address: "0xeAcB5c09612F90eb2EE3bA9896d8421084FFb921",
  },
  {
    name: "SwapinDao",
    abbr: "SWAPD",
    address: "0x67e7ebdA5CBA73f5830538B03E678A1b45517dd7",
  },
  {
    name: "Planq Pepe",
    abbr: "PEPE",
    address: "0xD350EcD60912913cc15d312ef38adECa909eCDD5",
  },
];

const GRAMSTokens = [
  {
    name: "GRAMS",
    abbr: "GRAMS",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "SwapinDao",
    abbr: "SWAPD",
    address: "0x67e7ebdA5CBA73f5830538B03E678A1b45517dd7",
  },
  {
    name: "DISCO",
    abbr: "DISCO",
    address: "0xA1b250ca9D350953F43ED20B44b85B608bf10E48",
  },
  {
    name: "PartyPepe",
    abbr: "PEPE",
    address: "0xD350EcD60912913cc15d312ef38adECa909eCDD5",
  },
  {
    name: "Pepita",
    abbr: "PEPI",
    address: "0x423d3EDBE3Ef7EaD5a16C78e6c7f748A97462f41",
  },
];

const OCTASPACETokens = [
  {
    name: "OCTASPACE",
    abbr: "OCTA",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "SwapinDao",
    abbr: "SWAPD",
    address: "0x67e7ebdA5CBA73f5830538B03E678A1b45517dd7",
  },
  {
    name: "OctaPepe",
    abbr: "PEPE",
    address: "0xD350EcD60912913cc15d312ef38adECa909eCDD5",
  },
  {
    name: "Pepita",
    abbr: "PEPI",
    address: "0x7E106F36D84Bd4362bC53b80b5Eeb1D7ac50ed3E",
  },
  {
    name: "Luckybucks",
    abbr: "LBC",
    address: "0x8DbC9F88b1a6292BaC0E8a214AD5223703aeB506",
  },
  {
    name: "PAC Token",
    abbr: "PAC",
    address: "0x760eE1038b54D5AdfeBbC9560Dd13F46a92D5b3a",
  },
];

const EGAZTokens = [
  {
    name: "EGAZ",
    abbr: "EGAZ",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Etica",
    abbr: "ETI",
    address: "0x34c61EA91bAcdA647269d4e310A86b875c09946f",
  },
];

const DEVNETTokens = [
  {
    name: "Altcoinchain",
    abbr: "ALT",
    address: "", // Weth address is fetched from the router
  },
];

const MAINNETTokens = [
  {
    name: "Ether-OFAC-Censored",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Dai",
    abbr: "DAI",
    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  },
  {
    name: "Wrapped BTC",
    abbr: "WBTC",
    address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
  },
  {
    name: "renBTC",
    abbr: "renBTC",
    address: "0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D",
  },
  {
    name: "GFY",
    abbr: "GFY",
    address: "0x4b40CcF6BE366219b0c4c17970D14760c7160739",
  },
  {
    name: "APU",
    abbr: "APU",
    address: "0x75EC8E766d8b30BaEA99110F5d2829c80e2b1A48",
  },
];

const POLYGONTokens = [
  {
    name: "MATIC",
    abbr: "MATIC",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Watt",
    abbr: "WATT",
    address: "0xe960d5076cd3169c343ee287a2c3380a222e5839",
  },
  {
    name: "Wrapped Ethereum",
    abbr: "WETH",
    address: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
  },
  {
    name: "Dai",
    abbr: "DAI",
    address: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  },
  {
    name: "USD Token",
    abbr: "USDC",
    address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  },
  {
    name: "binance-usd",
    abbr: "BUSD",
    address: "0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7",
  },
  {
    name: "Watt",
    abbr: "WATT",
    address: "0xe960d5076cd3169c343ee287a2c3380a222e5839",
  },
];

const BINANCETokens = [
  {
    name: "Binance Token",
    abbr: "BNB",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Wrapped Ethereum",
    abbr: "ETH",
    address: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "",
  },
  {
    name: "USD Token",
    abbr: "USDC",
    address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
  },
  {
    name: "binance-usd",
    abbr: "BSC-USD",
    address: "0x55d398326f99059ff775485246999027b3197955",
  },
  {
    name: "Pancakeswap",
    abbr: "CAKE",
    address: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
  },
  {
    name: "binance-busd",
    abbr: "BUSD",
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
  },
  {
    name: "Flux",
    abbr: "FLUX",
    address: "0xaff9084f2374585879e8b434c399e29e80cce635",
  },
  {
    name: "Old LBC, do not buy",
    abbr: "LBC",
    address: "0x59dbfdc05a1E460DD374167a4041c31275EbcE04",
  },
];
const DOGETokens = [
  {
    name: "DOGEcoin",
    abbr: "DOGE",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "DogePepe",
    abbr: "PEPE",
    address: "0xD350EcD60912913cc15d312ef38adECa909eCDD5",
  },
];
const KAVATokens = [
  {
    name: "KAVA",
    abbr: "KAVA",
    address: "", // Weth address is fetched from the router
  },
];
const FANTOMTokens = [
  {
    name: "FANTOM",
    abbr: "FTM",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "SwapinDao",
    abbr: "SWAPD",
    address: "0x67e7ebdA5CBA73f5830538B03E678A1b45517dd7",
  },
  {
    name: "FantomPepe",
    abbr: "PEPE",
    address: "0xD350EcD60912913cc15d312ef38adECa909eCDD5",
  },
  {
    name: "Pepita",
    abbr: "PEPI",
    address: "0x71fd222475361258d09a01d71fd71e4d7a0265be",
  },
  {
    name: "BEER",
    abbr: "BEER",
    address: "0x94Ba6241BC7E6F7F24a5B95fE6726B31C74881aB",
  },
  {
    name: "YURI",
    abbr: "YURI",
    address: "0xeF1608b8B01797Df5d46755f51fb126f21D0e7fa",
  },
  {
    name: "YURI+",
    abbr: "YURI+",
    address: "0x3b4f6bac86e3fd769d075c647d3b98b2af4375e1",
  },
];
const EGEMTokens = [
  {
    name: "EGEM",
    abbr: "EGEM",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "SwapinDao",
    abbr: "SWAPD",
    address: "0x67e7ebdA5CBA73f5830538B03E678A1b45517dd7",
  },
  {
    name: "EgemPepe",
    abbr: "PEPE",
    address: "0xD350EcD60912913cc15d312ef38adECa909eCDD5",
  },
  {
    name: "Pepita",
    abbr: "PEPI",
    address: "0x8e29B191DC9DD3fdb16F42336f9C822610B93B22",
  },
  {
    name: "WBNB (BSC)",
    abbr: "WBNB",
    address: "0x97271a78C9778F226C1c4eFe63876c04f8cD8F40",
  },
  {
    name: "TUSD (BSC)",
    abbr: "TUSD",
    address: "0x14016e85a25aeb13065688cafb43044c2ef86784",
  },
  {
    name: "WETH (Polygon)",
    abbr: "WETH",
    address: "0xC99A0e60fB489Ab229140D0F5751916858Ab62C8",
  },
  {
    name: "WMATIC (Polygon)",
    abbr: "WMATIC",
    address: "0x95e3D9C12C1a8bF3c80782283900FfE77fB8c295",
  },
];
const COINS = new Map();
COINS.set(chains.ChainId.MAINNET, MAINNETTokens);
COINS.set(chains.ChainId.POLYGON, POLYGONTokens);
COINS.set(chains.ChainId.PLANQ, PLANQTokens);
COINS.set(chains.ChainId.DEVNET, DEVNETTokens);
COINS.set(chains.ChainId.ALTCOINCHAIN, ALTCOINCHAINTokens);
COINS.set(chains.ChainId.BINANCE, BINANCETokens);
COINS.set(chains.ChainId.DOGE, DOGETokens);
COINS.set(chains.ChainId.KAVA, KAVATokens);
COINS.set(chains.ChainId.FANTOM, FANTOMTokens);
COINS.set(chains.ChainId.ETHO, ETHOTokens);
COINS.set(chains.ChainId.EGEM, EGEMTokens);
COINS.set(chains.ChainId.GRAMS, GRAMSTokens);
COINS.set(chains.ChainId.EGAZ, EGAZTokens);
COINS.set(chains.ChainId.OCTASPACE, OCTASPACETokens);
export default COINS;
