import React from "react";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import companyLogo from "./logo512.png";

const styles = (theme) => ({
  paperContainer: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    maxWidth: 700,
    margin: "auto",
    marginTop: "200px",
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  hr: {
    width: "100%",
  },
  balance: {
    padding: theme.spacing(1),
    overflow: "wrap",
    textAlign: "center",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(0.4),
  },
  footer: {
    marginTop: "45px",
  },
});

const useStyles = makeStyles(styles);

function ConnectWalletPage() {
  const classes = useStyles();
  return (
    <div>
      <div className="Title">
        {" "}
        <img src={companyLogo} alt="Swapin.co DEX"></img>
        <h1 className="navbar-logo"> </h1>
      </div>

      <Container>
        <Paper className={classes.paperContainer}>
          <Typography
            variant="h6"
            className={classes.title}
            color="common.white"
          >
            Please connect an Ethereum wallet to your browser to use the
            application, or switch to a supported blockchain.
          </Typography>
        </Paper>
      </Container>

      <Grid
        container
        className={classes.footer}
        direction="row"
        justifyContent="center"
        alignItems="flex-center"
      >
        <p align="flex-center">
          <a href="https://altcoinchain.org/">ALT</a> -{" "}
          <a href="http://www.partyexplorer.co/">GRAMS</a> -{" "}
          <a href="https://explorer.octa.space/">OCTA</a> -{" "}
          <a href="https://dogechain.dog/">DOGE</a> -{" "}
          <a href="https://explorer.ethoprotocol.com/">ETHO</a> -{" "}
          <a href="https://egem.io/">EGEM</a> -{" "}
          <a href="https://ftmscan.com/">FTM</a> -{" "}
          <a href="https://eticascan.org/">EGAZ</a> -{" "}
          <a href="https://evm.planq.network/">PLQ</a> <br></br>
          <br></br>
          <a href="https://forms.gle/qFdcJTx13Qt13LHJA">List Token</a> -{" "}
          <a href="https://twitter.com/swapinco">Twitter</a> -{" "}
          <a href="https://discord.gg/qejCwu2qrp">Discord</a> -{" "}
          <a href="https://github.com/swapinco">Github</a>
        </p>
      </Grid>
    </div>
  );
}

export default ConnectWalletPage;
