import React, { useState, useEffect } from "react";
import { Contract, ethers } from "ethers";
//import { JsonRpcProvider } from "ethers/providers";
//import { ethers } from "ethers";
//import ConnectButton from "../ConnectButton";
//import PresaleAbi from "./PresaleAbi";
//import SwapdAbi from "./SwapdAbi";
import {
  getAccount,
  getFactory,
  getProvider,
  getRouter,
  getSigner,
  getNetwork,
  getAmountOut,
  getBalanceAndSymbol,
  getWeth,
  swapTokens,
  getReserves,
} from "../ethereumFunctions";
const PresaleContract = "0xf4edaa693e724250502e727507217bbce5a903a4";
const SwapdAddress = "0x67e7ebdA5CBA73f5830538B03E678A1b45517dd7";
//const PresaleAbi = { PresaleAbi };
const { PresaleAbi } = require("./PresaleAbi");
const { SwapdAbi } = require("./SwapdAbi");
//const providerUrl = "https://rpc-mumbai.matic.today";
//const provider = new JsonRpcProvider(providerUrl);
const tokenPriceInWei = 5000000000000000; // 0.005 Ether in Wei

const Presale = () => {
  const [amount, setAmount] = useState("");
  const [total, setTotal] = useState("");
  const [available, setAvailable] = useState("");

  const updateTotal = (newAmount) => {
    const newTotal = newAmount * tokenPriceInWei;
    const newTotalInEther = parseFloat(
      ethers.formatEther(newTotal.toString())
    ).toFixed(2);
    setTotal(newTotalInEther);
  };

  useEffect(() => {
    const fetchAvailable = async () => {
      const tokenContract = new Contract(SwapdAddress, SwapdAbi);
      const balance = await tokenContract.balanceOf(PresaleContract);
      setAvailable(balance.toString());
    };

    fetchAvailable();
  }, []);

  const Purchase = async (props) => {
    const signer = props.network.signer();
    const contract = new Contract(PresaleContract, PresaleAbi, signer);
    await contract.Purchase({ value: ethers.parseEther(total) });
  };

  return (
    <div>
      <h1>Buy SWAPD SwapinDAO Tokens</h1>

      <input
        type="number"
        value={amount}
        onChange={(e) => {
          setAmount(e.target.value);
          updateTotal(e.target.value);
        }}
      />
      <p>Total to pay: {total} GRAMS</p>
      <p>Available to Purchase: {available}</p>
      <button onClick={Purchase}>Buy</button>
    </div>
  );
};

export default Presale;
