import React from "react";
import { ButtonGroup, Button } from "@material-ui/core";

export default function SwitchButton(props) {
  const { setDeploy } = props;

  const changeStyles = (K) => {
    if (K === true) {
      let dao_button = document.getElementById("dao-button");
      dao_button.style.backgroundColor = "#AA00E2";

      let swapd_button = document.getElementById("swapd-button");
      swapd_button.style.backgroundColor = "#9e9e9e";
    } else {
      let swapd_button = document.getElementById("swapd-button");
      swapd_button.style.backgroundColor = "#AA00E2";

      let dao_button = document.getElementById("dao-button");
      dao_button.style.backgroundColor = "#9e9e9e";
    }
  };

  return (
    <div>
      <ButtonGroup size="large" variant="contained">
        <Button
          id="dao-button"
          color="primary"
          text="white"
          onClick={() => {
            setDeploy(false);
            changeStyles(false);
          }}
        >
          SwapinDAO
        </Button>

        <Button
          id="swapd-button"
          color="secondary"
          text="white"
          onClick={() => {
            setDeploy(true);
            changeStyles(true);
          }}
        >
          Buy SWAPD
        </Button>
      </ButtonGroup>
    </div>
  );
}
