export const networks = [
  1, 56, 137, 250, 1773, 1987, 2000, 2222, 2330, 7070, 61803, 800001, 1313114,
  65110000,
];

export const ChainId = {
  MAINNET: 1,
  BINANCE: 56,
  POLYGON: 137,
  FANTOM: 250,
  //  KUCOIN: 321,
  //  THETA: 361,
  GRAMS: 1773,
  EGEM: 1987,
  DOGE: 2000,
  KAVA: 2222,
  ALTCOINCHAIN: 2330,
  PLANQ: 7070,
  EGAZ: 61803,
  OCTASPACE: 800001,
  ETHO: 1313114,
  DEVNET: 65110000,
};

export const multisendAddress = new Map();
multisendAddress.set(
  ChainId.ALTCOINCHAIN,
  "0xCF110A9F7c705604190f9Dd6FDf0FC79D00D569B"
);
multisendAddress.set(
  ChainId.EGAZ,
  "0xCF110A9F7c705604190f9Dd6FDf0FC79D00D569B"
);
multisendAddress.set(
  ChainId.FANTOM,
  "0x5a03b79b6F4cbb1eC8276a87b74a9304D05442C7"
);
multisendAddress.set(
  ChainId.PLANQ,
  "0x5a03b79b6F4cbb1eC8276a87b74a9304D05442C7"
);
multisendAddress.set(
  ChainId.POLYGON,
  "0x662adc895573D4131F2c3C9c6D0E5CdD1a803E6C"
);
multisendAddress.set(
  ChainId.DOGE,
  "0x5a03b79b6F4cbb1eC8276a87b74a9304D05442C7"
);
multisendAddress.set(
  ChainId.BINANCE,
  "0x1fdFC039f0433f319E0d3A33e28dF2e8057d0da0"
);
multisendAddress.set(
  ChainId.ECHELON,
  "0x286a231F619734Bc5cEc3ca600984d237818E7ca"
);
multisendAddress.set(
  ChainId.ETHO,
  "0x5a03b79b6F4cbb1eC8276a87b74a9304D05442C7"
);
multisendAddress.set(
  ChainId.EGEM,
  "0x5a03b79b6F4cbb1eC8276a87b74a9304D05442C7"
);
multisendAddress.set(
  ChainId.GRAMS,
  "0xCF110A9F7c705604190f9Dd6FDf0FC79D00D569B"
);
multisendAddress.set(
  ChainId.OCTASPACE,
  "0xCF110A9F7c705604190f9Dd6FDf0FC79D00D569B"
);

export const routerAddress = new Map();
routerAddress.set(
  ChainId.ALTCOINCHAIN,
  "0xae168Ce47cebca9abbC5107a58df0532f1afa4d6"
);
routerAddress.set(ChainId.EGAZ, "0xae168Ce47cebca9abbC5107a58df0532f1afa4d6");
routerAddress.set(ChainId.PLANQ, "0xae168Ce47cebca9abbC5107a58df0532f1afa4d6");
routerAddress.set(
  ChainId.MAINNET,
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D"
);
routerAddress.set(
  ChainId.POLYGON,
  "0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff"
);
routerAddress.set(
  ChainId.BINANCE,
  "0x10ed43c718714eb63d5aa57b78b54704e256024e"
);
//routerAddress.set(ChainId.ETHO, "");
//routerAddress.set(ChainId.ECHELON, "");
routerAddress.set(ChainId.DOGE, "0xae168Ce47cebca9abbC5107a58df0532f1afa4d6");
//routerAddress.set(ChainId.KAVA, "");
routerAddress.set(ChainId.EGEM, "0xae168Ce47cebca9abbC5107a58df0532f1afa4d6");
routerAddress.set(ChainId.ETHO, "0xae168Ce47cebca9abbC5107a58df0532f1afa4d6");
routerAddress.set(ChainId.FANTOM, "0xae168Ce47cebca9abbC5107a58df0532f1afa4d6");
routerAddress.set(ChainId.DEVNET, "0x04e555283D37aE85F6eB311fe2578F3B3f3dFc52");
routerAddress.set(ChainId.GRAMS, "0xae168Ce47cebca9abbC5107a58df0532f1afa4d6");
routerAddress.set(
  ChainId.OCTASPACE,
  "0xae168Ce47cebca9abbC5107a58df0532f1afa4d6"
);
