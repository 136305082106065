export const MenuItems = [
  {
    title: "Swap",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "Liquidity",
    url: "/liquidity",
    cName: "nav-links",
  },
  // {
  //    title: "Multisend",
  //    url: "/multisend",
  //    cName: "nav-links",
  //  },

  //  {
  //    title: "SWAPD",
  //    url: "/swapindao",
  //    cName: "nav-links",
  //  },
];
