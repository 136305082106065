import React from "react";
import {
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import SwitchButton from "./SwitchButton";
import LiquidityDeployer from "./LiquidityDeployer";
import LiquidityRemover from "./RemoveLiquidity";
import { CenterFocusStrong } from "@material-ui/icons";

const styles = (theme) => ({
  paperContainer: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    maxWidth: 700,
    margin: "auto",
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  footer: {
    marginTop: "45px",
  },
});

const useStyles = makeStyles(styles);

function Liquidity(props) {
  const classes = useStyles();

  const [deploy, setDeploy] = React.useState(true);

  const deploy_or_remove = (deploy) => {
    if (deploy === true) {
      return <LiquidityDeployer network={props.network} />;
    }
    return <LiquidityRemover network={props.network} />;
  };

  return (
    <div>
      <Container>
        <Paper className={classes.paperContainer}>
          <Typography variant="h5" className={classes.title}>
            <SwitchButton setDeploy={setDeploy} />
          </Typography>

          {deploy_or_remove(deploy)}
        </Paper>
      </Container>

      <Grid
        container
        className={classes.footer}
        direction="row"
        justifyContent="center"
        alignItems="flex-center"
      >
        <p align="flex-center">
          <a href="https://altcoinchain.org/">ALT</a> -{" "}
          <a href="http://www.partyexplorer.co/">GRAMS</a> -{" "}
          <a href="https://explorer.octa.space/">OCTA</a> -{" "}
          <a href="https://dogechain.dog/">DOGE</a> -{" "}
          <a href="https://explorer.ethoprotocol.com/">ETHO</a> -{" "}
          <a href="https://egem.io/">EGEM</a> -{" "}
          <a href="https://ftmscan.com/">FTM</a> -{" "}
          <a href="https://eticascan.org/">EGAZ</a> -{" "}
          <a href="https://evm.planq.network/">PLQ</a> <br></br>
          <br></br>
          <a href="https://forms.gle/qFdcJTx13Qt13LHJA">List Token</a> -{" "}
          <a href="https://twitter.com/swapinco">Twitter</a> -{" "}
          <a href="https://discord.gg/qejCwu2qrp">Discord</a> -{" "}
          <a href="https://github.com/swapinco">Github</a>
        </p>
      </Grid>
    </div>
  );
}

export default Liquidity;
